const setPostPublishDate = (data) => {
  let date = data.date_publish;
  return {
    date: date.substring(0, 10),
    hour: parseInt(date.substring(11, 13)),
    min:  parseInt(date.substring(14 ,16)),
    sec:  parseInt(date.substring(17))
  }
}

export default setPostPublishDate;