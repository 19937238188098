import {apiRequest} from '@/utils/axios/axiosInstance.js';

/**
 * get post item by post type and post id
 * @param {string} type 
 * @param {string} id 
 */
const getPostItem = (id) => {
  return apiRequest.get('/content/post-item/' + id);
}

export default getPostItem;
