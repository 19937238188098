import setPostPublishDate from '@/utils/post/setPostPublishDate.js';

const preparePostItem = (res) => {
  if(res.data.status!=200) {
    alert('การดึงข้อมูลมีปัญหา');
    return false;
  }

  let data = res.data.result;
  data.publish = setPostPublishDate(data);
  data.publish = setPostPublishDate(data);

  // if slug is null, set empty string instead.
  if(data.slug===null) {
    data.slug = '';
  }

  // set content status to 'draft' when status is 'new' or 'trash'
  if(data.status=='new' || data.status=='trash') {
    data.formType = 'add';
    data.status = 'draft';
  }else{
    data.formType = 'edit';
  }

  //assign meta data when meta is emtpy
  const defaultMeta = {
    constructor_name: '',
    url: '',
    seo_title: '',
    seo_description: '',
    seo_keyword: '',
  };
  if(Object.keys(data.meta).length==0) {
    data.meta = defaultMeta;
  }else{
    Object.keys(defaultMeta).map((key) => {
      if(data.meta[key] === undefined) {
        data.meta[key] = '';
      }
    })
  }

  // if data haven't banner meta data
  // function will prepare default data
  if(!('banner' in data.meta)) {
    data.meta.banner = {};
    for (let i=1 ; i <= 6 ; i++) {
      data.meta.banner['b'+i] = {title: '', url: ''};
    }
  }

  return data;
}

export default preparePostItem;


