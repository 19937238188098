<template>
  <div v-if="getLoaded()" class="duck-form form-width-large form-center">
    <NotiBar v-if="msg.show" :type="msg.type" :msg="msg.text" />
    
    <form @submit.prevent="saveForm">
      <fieldset class="form-block">
        <div class="h-title">
          <h3>เนื้อหาบทความ</h3>
        </div>

        <div class="form-container">
          <div class="input">
            <Label text="หัวข้อเนื้อหา" />
            <input type="text" v-model="contentData.title">
          </div>
          <div class="input">
            <Label text="หัวข้อใน url" />
            <input type="text" v-model="contentData.slug">
            <div class="preview-link">
              <a :href="getPreviewLink()" title="preview link" target="_blank">
                <i class="fa fa-link"></i>
                {{ getPreviewLink() }}
              </a>
            </div>
          </div>

          <div class="input">
            <Label text="คำอธิบาย" />
            <textarea v-model="contentData.description"></textarea>
          </div>
          
          <div class="input row3">
            <div class="col">
              <Label text="สถานะเนื้อหา" />
              <select v-model="contentData.status">
                <option value="draft">Draft</option>
                <option value="publish">Publish</option>
              </select>
            </div>
            <div class="col">
              <Label text="วันที่แสดง" />
              <datepicker v-model.trim="contentData.publish.date" format="yyyy-MM-dd"></datepicker>
            </div>
            <div class="col">
              <Label text="เวลาที่แสดง" />
              <select v-model="contentData.publish.hour" class="select-time">
                <option v-for="i in 24" :key="i">{{ i-1 }}</option>
              </select>
              :
              <select v-model="contentData.publish.min" class="select-time">
                <option v-for="i in 60" :key="i">{{ i-1 }}</option>
              </select>
              :
              <select v-model="contentData.publish.sec" class="select-time">
                <option v-for="i in 60" :key="i">{{ i-1 }}</option>
              </select>
            </div>
          </div>

          <div class="row2">
            <div class="col col-thumb">
              <Label text="รูปภาพประกอบ" />
              <SingleImageUploader type="thumbnail" section="content" :parentId="contentData.ID" description="แนะนำขนาด 800x960 pixel" />
            </div>
          </div>
          
          <div class="row2">
            <div class="col col-thumb">
              <Label text="โลโก้องค์กร" />
              <SingleImageUploader type="company_logo" section="content" :parentId="contentData.ID" description="แนะนำขนาด 400x400 pixel" />
            </div>
          </div>

          <div class="input">
            <Label text="ไฟล์ประวัติผู้รับเหมา" />
            <SingleFileUploader type="const_profile" section="content" :parentId="contentData.ID" />
          </div>

          <div class="input">
            <Label text="รายละเอียด" />
            <TextEditor v-model="contentData.detail" :uploaderKey="contentData.ID" />
          </div>
        </div>
      </fieldset>

      <div class="button-block">
        <button type="submit" class="btn btn-update">
          <i class="fa fa-save fa-lg"></i> บันทึกข้อมูล
        </button>
      </div>
    </form>
  </div>
</template>


<script>
// @ is an alias to /src
import {globalVars} from '@/variables/config.js';
import moment       from 'moment';


import getPostItem        from '@/utils/post/getPostItem.js';
import {apiRequest}       from '@/utils/axios/axiosInstance.js';
import preparePostItem    from '@/utils/post/preparePostItem.js';

import setPostSlugToURI from '@/utils/post/setPostSlugToURI.js';

import Datepicker         from 'vuejs-datepicker';
import NotiBar            from '@/components/common/NotiBar.vue';
import Label              from '@/components/common/form/Label.vue';
import TextEditor         from '@/components/common/form/TextEditor.vue';
import SingleImageUploader from '@/components/file/SingleImageUploader.vue';
import SingleFileUploader  from '@/components/file/SingleFileUploader.vue';

export default {
  data() {
    return {
      pageTitle: 'Recommended',
      loaded: false,
      msg: {
        show: false,
        type: 'success',
        text: '',
      },

      contentId: '',
      contentData: {
        publish: {},
      },
      isEditForm: false,
    }
  },
  components: {
    Datepicker,
    Label,
    NotiBar,
    SingleImageUploader,
    SingleFileUploader,
    TextEditor,
  },
  created() {
    this.contentId = this.$route.params.id;
    this.loadPage();
  },
  methods: {
    getLoaded() {
      return !this.$store.state.show_loader;
    },
    loadPage:function() {
      this.$store.commit('setLoaderShow', true);

      getPostItem(this.contentId)
        .then((res) => {
          this.contentData = preparePostItem(res);

          this.isEditForm = this.contentData.formType == 'edit';

          this.setPageTitle();
          this.$store.commit('setLoaderShow', false);
        });
    },
    saveForm:function() {
      this.$store.commit('setLoaderShow', true);

      this.contentData.date_publish  = this.dateSaveFormat();
      this.contentData.slug  = this.slug2uri( this.contentData.slug );
      
      // console.log(this.dateSaveFormat());
      // console.log(this.contentData);

      apiRequest
        .post('/content/save-post', this.contentData)
        .then( (res) => {
          this.msg.show = true;

          console.log(res.data);
          
          if(res.data.status==200) {
            this.msg.text = 'Save data complete';
            // replace new slug
            if(this.contentData.slug != res.data.result.slug) {
              this.contentData.slug = res.data.result.slug;
            }

            // force change this page to Edit Form
            this.isEditForm = true;
            this.setPageTitle();
          }else{
            this.msg.type = 'error';
            this.msg.text = 'Save data fail. Please, try again.';
          }

          this.$store.commit('setLoaderShow', false);
        });
    },

    /**
     * Set page title to 'Add' or 'Edit'
     */
    setPageTitle() {
      let title = ( this.isEditForm ? 'Edit ':'Add ' ) + this.pageTitle;
      this.$store.commit('setPageTitle', title);
      this.$store.commit('setHeadTitle', title);
    },

    /**
     * get show contom banner status
     */
    getShowCustomBanner() {
      return this.contentData.section == 'homeguru';
    },

    /**
     * Get preview for display in frontend
     */
    getPreviewLink() {
      return  globalVars.url.front + 
              this.contentData.section +
              '/view/' +
              this.slug2uri( this.contentData.slug );
              // this.contentData.ID + '/' +
    },

    /**
     * prepare date format
     */
    customFormatter(date) {
      return moment(date).format('YYYY-MM-DD');
    },

    /**
     * date save format
     */
    dateSaveFormat() {
      let publish = this.contentData.publish;
      return  this.customFormatter(publish.date) +' '+
              this.zeroAfter(publish.hour)+':'+
              this.zeroAfter(publish.min)+':'+
              this.zeroAfter(publish.sec);
    },

    /**
     * add '0' if less than 10
     */
    zeroAfter(num) {
      return num < 10 ? '0'+num : num;
    },

    /**
     * Convert slug to url format
     */
    slug2uri(str) {
      return setPostSlugToURI(str);
    }
  }
}
</script>

<style scoped>
.col-thumb {padding-right: 15px;}

.preview-link {
  margin-top: 5px;
}
.select-time {
  padding-left: 5px;
  width: 45px;
}

.mt-5 {margin-top: 5px;}
.form-banner .input {padding-bottom: 15px;}
.form-banner .input+.input { 
  padding-top: 25px;
  border-top: solid 2px #000;
}
</style>